@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  position: relative;
  min-height: 400px;
  padding: 64px 0;
}

.content {
  z-index: 0;
  width: 100%;
}
